import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import ErrorDisplay from "../reusable/ErrorDisplay"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import { Select, MenuItem, FormControl, Button, InputLabel } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { useAuth0 } from "@auth0/auth0-react"
import { recalculateScore, wpSync } from "../../helpers/ItemHelper"
import SnackbarComponent from "../reusable/SnackbarComponent"
import CloudSyncIcon from "@mui/icons-material/CloudSync"
import Grid from "@mui/material/Grid"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  heading: {
    fontSize: 16,
    color: "#575757",
    marginBottom: 20,
  },
  subHeading: {
    fontSize: 12,
    color: "#575757",
  },
  chartContainer: {
    textAlign: "center",
  },
}))

const RecalculateScores = (props) => {
  const { markets, errorDetails, isLoading } = props
  const classes = useStyles()

  const { getAccessTokenSilently, user } = useAuth0()
  const { name: currentUserName } = user

  const [selectedOption, setSelectedOption] = useState("")
  const [availableMarkets, setAvailableMarkets] = useState([])
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarProps, setSnackbarProps] = useState({})
  const [syncBtnDisabled, setSyncBtnDisabled] = useState(false)
  const [recalculateBtnDisabled, setRecalculateBtnDisabled] = useState(false)

  useEffect(() => {
    if (Array.isArray(markets) && markets.length > 0) {
      const tempAvailableMarkets = []
      markets.forEach((market) => {
        if (market.shortName !== "Central") {
          tempAvailableMarkets.push(market.shortName)
        }
      })
      setAvailableMarkets(tempAvailableMarkets)
      setSelectedOption(tempAvailableMarkets[0])
    }
  }, [markets])

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  const sendRecalculateRequest = (market) => {
    return new Promise((resolve, reject) => {
      getAccessTokenSilently()
        .then((token) => {
          recalculateScore(
            token,
            `/recalculatescore`,
            currentUserName,
            market,
            (results) => {
              if (results && results.status === 200) {
                resolve({ market, success: true })
              } else {
                console.error("error: ", results)
                resolve({ market, success: false })
              }
            }
          )
        })
        .catch((error) => {
          console.error("Token error: ", error)
          reject(error)
        })
    })
  }

  const handleRecalculateClick = async () => {
    setRecalculateBtnDisabled(true)
    const recalculationPromises = []

    try {
      if (selectedOption === "All Markets") {
        availableMarkets.forEach((market) => {
          if (market !== "All Markets") {
            recalculationPromises.push(sendRecalculateRequest(market))
          }
        })
      } else {
        recalculationPromises.push(sendRecalculateRequest(selectedOption))
      }

      const results = await Promise.all(recalculationPromises)

      // Analyze results
      const failedMarkets = results
        .filter((result) => !result.success)
        .map((r) => r.market)
      if (failedMarkets.length === 0) {
        // All markets succeeded
        setShowSnackbar(true)
        setSnackbarProps({
          messageClass: classes.snackbarMessage,
          closeEvent: handleCloseSnackbar,
          message: `Scores for ${
            selectedOption === "All Markets" ? "all markets" : selectedOption
          } recalculated successfully.`,
          icon: <CheckIcon />,
          autoHideDuration: 3000,
        })
      } else {
        // Some markets failed
        setShowSnackbar(true)
        setSnackbarProps({
          class: classes.errorSnackbar,
          messageClass: classes.snackbarMessage,
          closeEvent: handleCloseSnackbar,
          message: `Error occurred in markets: ${failedMarkets.join(", ")}`,
          icon: <ErrorOutlineIcon />,
        })
      }
    } catch (error) {
      console.error("Recalculation process failed: ", error)
      setShowSnackbar(true)
      setSnackbarProps({
        class: classes.errorSnackbar,
        messageClass: classes.snackbarMessage,
        closeEvent: handleCloseSnackbar,
        message: "A critical error occurred during recalculation.",
        icon: <ErrorOutlineIcon />,
      })
    } finally {
      setRecalculateBtnDisabled(false)
    }
  }
  const handleSyncClick = async (e) => {
    setSyncBtnDisabled(true)
    setShowSnackbar(true)
    setSnackbarProps({
      messageClass: classes.snackbarMessage,
      message: "Sync into WP in process",
      icon: <CloudSyncIcon />,
    })
    wpSync("score", "", "", false, (syncResults) => {
      if (syncResults.status && syncResults.status === "updated") {
        setShowSnackbar(true)
        setSnackbarProps({
          messageClass: classes.snackbarMessage,
          closeEvent: handleCloseSnackbar,
          message: syncResults.message,
          icon: <CheckIcon />,
          autoHideDuration: 3000,
        })
      } else {
        console.log("Data sync error: ", syncResults)
        setShowSnackbar(true)
        setSnackbarProps({
          class: classes.errorSnackbar,
          messageClass: classes.snackbarMessage,
          closeEvent: handleCloseSnackbar,
          message: "Sync Error: " + syncResults.message,
          icon: <ErrorOutlineIcon />,
        })
      }
      setSyncBtnDisabled(false)
    })
  }

  return (
    <div className={classes.root}>
      {showSnackbar && <SnackbarComponent open={showSnackbar} {...snackbarProps} />}
      <Typography variant="h6" className={classes.heading}>
        Recalculate Scores
      </Typography>
      {(isLoading && <div></div>) ||
        (errorDetails && (
          <ErrorDisplay
            title="Something went wrong"
            description={errorDetails}
            noPadding={true}
          />
        )) || (
          <div>
            <FormControl fullWidth>
              <InputLabel id="market-select-label">Select Market</InputLabel>
              <Select
                labelId="market-select-label"
                value={selectedOption}
                label="Select Market"
                onChange={handleSelectChange}
              >
                {availableMarkets.map((market) => (
                  <MenuItem key={market} value={market}>
                    {market}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid container justifyContent="space-between">
              <Grid display="flex" size="auto">
                <Button
                  variant="contained"
                  onClick={handleRecalculateClick}
                  style={{ marginTop: 20 }}
                  disabled={recalculateBtnDisabled}
                >
                  Recalculate Scores
                </Button>
              </Grid>
              <Grid display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={syncBtnDisabled}
                  onClick={handleSyncClick}
                  style={{ marginTop: 20 }}
                >
                  Sync Scores
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
    </div>
  )
}

export default RecalculateScores

RecalculateScores.propTypes = {
  markets: PropTypes.array,
  errorDetails: PropTypes.string,
  isLoading: PropTypes.bool,
}
