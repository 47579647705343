import React, { useState, useEffect } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { useStateValue } from "../../state"
import UserComponent from "./UserComponent"
import useDebounce from "../../helpers/useDebounce"
import AppBar from "@mui/material/AppBar"
import Typography from "@mui/material/Typography"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Badge from "@mui/material/Badge"
import Drawer from "@mui/material/Drawer"
import Hidden from "@mui/material/Hidden"
import IconButton from "@mui/material/IconButton"
import InfoIcon from "@mui/icons-material/Info"
import MenuIcon from "@mui/icons-material/Menu"
import Grid from "@mui/material/Grid"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import { makeStyles, useTheme } from "@mui/styles"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import RestoreIcon from "@mui/icons-material/Restore"
import RefreshIcon from "@mui/icons-material/Refresh"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import InputBase from "@mui/material/InputBase"
import Popover from "@mui/material/Popover"
import SearchIcon from "@mui/icons-material/Search"
import FilterListIcon from "@mui/icons-material/FilterList"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import AppMenu from "../appmenu/AppMenu"
import { StyledEngineProvider } from "@mui/material/styles"
import { alpha } from "@mui/material"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
  },
  barShape: {
    "&::after": {
      content: "''",
      backgroundColor: "#7580c340",
      display: "block",
      height: 300,
      borderRadius: "50% 0 0 50%",
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    zIndex: -1,
    position: "absolute",
    right: 0,
    top: 0,
    overflow: "hidden",
    height: "100%",
    width: 500,
  },
  backNav: {
    display: "flex",
    color: "#f1f1f1",
    marginRight: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerHead: {
    fontSize: "14px",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.colors.primary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // Necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerPaper: {
    width: drawerWidth,
    color: "#fff",
    backgroundColor: theme.colors.drawer.background,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#f1f1f1",
    padding: theme.spacing(2),
  },
  contentInner: {
    background: "transparent",
    maxWidth: 1680,
    borderRadius: ".375rem",
    overflow: "hidden",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  actionArea: {
    marginLeft: "auto",
    marginRight: 20,
    color: "#fff",
  },
  actionButton: {
    color: "#fff",
    "&:hover": {
      opacity: 0.8,
    },
    "&.toggle-bulk-edit-active svg": {
      color: "#2eb902",
    },
  },
  createButton: {
    color: "#fff",
    "&:hover": {
      color: "#d3d3d3",
    },
    "& svg": {
      fontSize: "2rem",
    },
  },
  filterButtonActive: {
    opacity: 0.8,
  },
  filterButtonHasValue: {
    color: "red",
  },
  filterPopoverContent: {
    padding: theme.spacing(2, 0),
  },
  missingValueFilter: {
    padding: theme.spacing(2, 4, 3),
    width: 500,
  },
  standardFilter: {
    padding: theme.spacing(2, 4, 3),
    width: 500,
  },
  fieldFilterInput: {
    margin: theme.spacing(2, 0),
  },
}))

function AppBarComponent(props) {
  const { children } = props

  const classes = useStyles()

  const theme = useTheme()

  const location = useLocation()

  const history = useHistory()

  const [{ appBarContext }, dispatch] = useStateValue()

  const [mobileOpen, setMobileOpen] = useState(false),
    [searchTerm, setSearchTerm] = useState(""),
    [fieldFilters, setFieldFilters] = useState({}),
    [missingValueFilter, setMissingValueFilter] = useState([]),
    [filterPopoverAnchorEl, setfilterPopoverAnchorEl] = useState(null),
    [lastFields, setLastFields] = useState(undefined)

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const debouncedFieldFilters = useDebounce(fieldFilters, 300)

  const debouncedMissingValueFilter = useDebounce(missingValueFilter, 300)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Typography variant="h6" noWrap>
          {process.env.REACT_APP_ENV === "development"
            ? "Stage Affiliate Admin"
            : process.env.REACT_APP_ENV === "local"
            ? "Local Affiliate Admin"
            : "Affiliate Admin"}
        </Typography>
      </div>
      <Divider />
      <AppMenu />
    </div>
  )

  // Clear missing value filter if field set is changed
  useEffect(() => {
    if (appBarContext.fields && appBarContext.fields.length > 0) {
      if (lastFields && lastFields.length > 0) {
        if (appBarContext.fields !== lastFields) {
          setFieldFilters({})
          setMissingValueFilter([])
        }
      }
      setLastFields(appBarContext.fields)
    }
  }, [appBarContext.fields, lastFields])

  // When the input has been changed, apply it to the context variable debounced to decrease lag
  useEffect(() => {
    dispatch({
      type: "changeAppBar",
      newAppBar: {
        searchTerm: debouncedSearchTerm || "",
      },
    })
  }, [debouncedSearchTerm, dispatch])

  // When the input has been changed, apply it to the context variable debounced to decrease lag
  useEffect(() => {
    dispatch({
      type: "changeAppBar",
      newAppBar: {
        activeFieldFilters: debouncedFieldFilters || [],
      },
    })
  }, [debouncedFieldFilters, dispatch])

  // When the input has been changed, apply it to the context variable debounced to decrease lag
  useEffect(() => {
    dispatch({
      type: "changeAppBar",
      newAppBar: {
        missingValueFilterFields: debouncedMissingValueFilter || [],
      },
    })
  }, [debouncedMissingValueFilter, dispatch])

  // If searchTerm context variable has been changed from elsewhere, apply it to the input
  useEffect(() => {
    if (appBarContext.searchTerm !== undefined) {
      setSearchTerm(appBarContext.searchTerm)
    }
  }, [appBarContext.searchTerm])

  let fieldFiltersCount = 0
  Object.keys(fieldFilters).forEach((k) => {
    fieldFiltersCount = fieldFiltersCount + fieldFilters[k].length
  })
  const totalActiveFilters = fieldFiltersCount + missingValueFilter.length

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.header}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {appBarContext.backNavUrl && (
              <Link className={classes.backNav} to={appBarContext.backNavUrl}>
                <ArrowBackIcon />
              </Link>
            )}
            <Typography variant="h6" noWrap>
              {appBarContext.title}
            </Typography>
            {appBarContext.enableSearch && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={searchTerm}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}

            <div className={classes.actionArea}>
              {appBarContext.toggleBulkEditAction && (
                <Button
                  className={classes.actionButton}
                  onClick={appBarContext.toggleBulkEditAction()}
                >
                  {appBarContext.toggleBulkEditActionTooltip ? (
                    <Tooltip title={appBarContext.toggleBulkEditActionTooltip}>
                      <EditIcon />
                    </Tooltip>
                  ) : (
                    <EditIcon />
                  )}
                </Button>
              )}
              {appBarContext.enableFilter && (
                <>
                  <Button
                    className={`${classes.actionButton} ${
                      totalActiveFilters && classes.filterButtonHasValue
                    } ${filterPopoverAnchorEl && classes.filterButtonActive}`}
                    onClick={(e) => setfilterPopoverAnchorEl(e.currentTarget)}
                  >
                    <Badge badgeContent={totalActiveFilters} color="secondary">
                      <Tooltip title="Filter items">
                        <FilterListIcon />
                      </Tooltip>
                    </Badge>
                  </Button>
                  <Popover
                    className={classes.filterPopover}
                    id={"missing-value-popover"}
                    open={Boolean(filterPopoverAnchorEl)}
                    anchorEl={filterPopoverAnchorEl}
                    onClose={(e) => setfilterPopoverAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classes.filterPopoverContent}>
                      {appBarContext.filterFields && (
                        <div className={classes.standardFilter}>
                          {appBarContext.filterFields.map((f) => (
                            <Autocomplete
                              className={classes.fieldFilterInput}
                              multiple
                              size="small"
                              key={"filter-" + f.fieldName}
                              id={"filter-" + f.fieldName}
                              options={f.options}
                              getOptionLabel={(option) => option.title}
                              isOptionEqualToValue={(option, value) =>
                                value && option.name === value.name
                              }
                              value={fieldFilters[f.fieldName] || []}
                              onChange={(event, value) => {
                                setFieldFilters({
                                  ...fieldFilters,
                                  [f.fieldName]: value,
                                })
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label={f.fieldTitle}
                                />
                              )}
                            />
                          ))}
                        </div>
                      )}
                      <div className={classes.missingValueFilter}>
                        <Grid container alignItems="flex-end">
                          <Grid item xs={11}>
                            <Autocomplete
                              multiple
                              id="missing-value-selector"
                              options={
                                appBarContext.fields &&
                                appBarContext.fields.length > 0
                                  ? appBarContext.fields
                                      .filter((f) => !f.headingTitle && !f.hidden)
                                      .map((f) => {
                                        return { title: f.title, name: f.name }
                                      })
                                  : []
                              }
                              getOptionLabel={(option) => option.title}
                              isOptionEqualToValue={(option, value) =>
                                value && option.name === value.name
                              }
                              onChange={(event, value) => {
                                setMissingValueFilter(value)
                              }}
                              value={missingValueFilter}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Missing information filter"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={1} style={{ textAlign: "right" }}>
                            <Tooltip
                              title="Find items with missing information by selecting one or more fields"
                              fontSize="small"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Popover>
                </>
              )}
              {appBarContext.refreshAction && (
                <Button
                  className={classes.actionButton}
                  onClick={appBarContext.refreshAction()}
                >
                  {appBarContext.refreshActionTooltip ? (
                    <Tooltip title={appBarContext.refreshActionTooltip}>
                      <RefreshIcon />
                    </Tooltip>
                  ) : (
                    <RefreshIcon />
                  )}
                </Button>
              )}
              {appBarContext.enableChangelog && (
                <Button
                  className={classes.actionButton}
                  onClick={appBarContext.changelogClicked()}
                >
                  <Tooltip title="Version history">
                    <RestoreIcon />
                  </Tooltip>
                </Button>
              )}
              {appBarContext.enableDelete && (
                <Button
                  className={classes.actionButton}
                  onClick={appBarContext.deleteAction()}
                  disabled={appBarContext.isSaving}
                >
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              )}
              {appBarContext.saveAction && (
                <Button
                  className={classes.actionButton}
                  onClick={appBarContext.saveAction()}
                  disabled={!appBarContext.saveEnabled || appBarContext.isSaving}
                >
                  <Tooltip title="Save">
                    <SaveIcon />
                  </Tooltip>
                </Button>
              )}
              {(appBarContext.enableCreate || appBarContext.enableAdd) && (
                <Button
                  className={classes.createButton}
                  onClick={
                    appBarContext.enableAdd && appBarContext.addAction
                      ? appBarContext.addAction()
                      : () => {
                          history.push(location.pathname + "/new")
                        }
                  }
                >
                  <Tooltip title="Add">
                    <AddIcon />
                  </Tooltip>
                </Button>
              )}
              <UserComponent />
            </div>
          </Toolbar>
          <div className={classes.barShape}></div>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.contentInner}>{children}</div>
        </main>
      </div>
    </StyledEngineProvider>
  )
}

AppBarComponent.propTypes = {
  children: PropTypes.element,
}

export default AppBarComponent
