import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { makeStyles } from "@mui/styles"
import { getItems, syncSlotsApi } from "../../helpers/ItemHelper"
import { Constants } from "../../config"
import LinkStatuses from "./LinkStatuses"
import FieldOverview from "./FieldOverview"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import RecalculateScores from "./RecalculateScores"
import { Button } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import SnackbarComponent from "../reusable/SnackbarComponent"
const paperBackgroundImage = require("../../images/photo-mountains.jpeg")
const paperBackgroundImageStage = require("../../images/photo-beach.webp")
const paperBackgroundImageLocal = require("../../images/photo-field.webp")

const paperBackground =
  process.env.REACT_APP_ENV === "development"
    ? paperBackgroundImageStage
    : process.env.REACT_APP_ENV === "local"
    ? paperBackgroundImageLocal
    : paperBackgroundImage

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 0,
    position: "relative",
  },
  paperWithBackgroundImage: {
    padding: theme.spacing(4),
    backgroundImage: "url(" + paperBackground + ")",
    backgroundSize: "100%",
    backgroundPosition: "18% 45%",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  },
  dayTitle: {
    position: "absolute",
    right: 20,
    top: 10,
    fontSize: "1rem",
    color: "#f1f1f1",
  },
  paperRoot: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: 16,
    color: "#575757",
    marginBottom: 20,
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
}))

const dayTitle = () => {
  const dateObj = new Date()
  const weekDay = dateObj.toLocaleString("default", { weekday: "long" })
  return (
    weekDay.charAt(0).toUpperCase() +
    weekDay.slice(1) +
    " " +
    dateObj.getDate() +
    "/" +
    (parseInt(dateObj.getMonth()) + 1)
  )
}

const apiRoutes = Constants.homeScreen.apiRoutes

const loadItems = (token, apiRoutes, index, itemsArray, callback) => {
  const apiRoute = apiRoutes[index]
  getItems(token, apiRoute.url, (results) => {
    if (results && Array.isArray(results)) {
      itemsArray.push({
        shortName: apiRoute.shortName,
        name: apiRoute.name,
        results,
      })
      index++
      if (index < apiRoutes.length) {
        loadItems(token, apiRoutes, index, itemsArray, callback)
      } else {
        callback(itemsArray)
      }
    } else {
      callback(false)
    }
  })
}

const HomeScreen = (props) => {
  const classes = useStyles()

  const { getAccessTokenSilently, user } = useAuth0()
  const { name: currentUserName } = user

  const [itemsArray, setItemsArray] = useState([]),
    [errorDetails, setErrorDetails] = useState(undefined),
    [isLoading, setIsLoading] = useState(true)

  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarProps, setSnackbarProps] = useState({})
  const [syncBtnDisabled, setSyncBtnDisabled] = useState(false)

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  const syncGameSuppliers = async () => {
    getAccessTokenSilently()
      .then((token) => {
        syncSlotsApi(token, `/syncslots`, currentUserName, (results) => {
          if (results && results.status === 200) {
            setShowSnackbar(true)
            setSnackbarProps({
              messageClass: classes.snackbarMessage,
              closeEvent: handleCloseSnackbar,
              message: results.message,
              icon: <CheckIcon />,
              autoHideDuration: 6000,
            })
          } else {
            console.error("error: ", results)
            setShowSnackbar(true)
            setSnackbarProps({
              class: classes.errorSnackbar,
              messageClass: classes.snackbarMessage,
              closeEvent: handleCloseSnackbar,
              message: results.message,
              icon: <ErrorOutlineIcon />,
            })
          }
        })
      })
      .catch((error) => {
        console.error("Token error: ", error)
        setShowSnackbar(true)
        setSnackbarProps({
          class: classes.errorSnackbar,
          messageClass: classes.snackbarMessage,
          closeEvent: handleCloseSnackbar,
          message: error,
          icon: <ErrorOutlineIcon />,
        })
      })
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      loadItems(token, apiRoutes, 0, [], (itemsArray) => {
        setIsLoading(false)
        if (itemsArray) {
          setItemsArray(itemsArray)
        } else {
          setErrorDetails("Could not load diagram")
        }
      })
    })
  }, [getAccessTokenSilently])

  return (
    <div className={classes.root}>
      {showSnackbar && <SnackbarComponent open={showSnackbar} {...snackbarProps} />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={`${classes.paper} ${classes.paperWithBackgroundImage}`}>
            <Typography variant="overline" className={classes.dayTitle}>
              {dayTitle()}
            </Typography>

            <h2>
              {process.env.REACT_APP_ENV === "development"
                ? "Welcome to stage online.casino"
                : "Welcome to admin online.casino"}
            </h2>
            <Typography paragraph>To Affiliate Admin</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <RecalculateScores
              markets={itemsArray}
              isLoading={isLoading}
              errorDetails={errorDetails}
            />
          </Paper>
        </Grid>
        {Constants.homeScreen.fieldOverviewSettings && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FieldOverview
                markets={itemsArray}
                isLoading={isLoading}
                errorDetails={errorDetails}
              />
            </Paper>
          </Grid>
        )}
        {Constants.homeScreen.enableLinkStatuses && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <LinkStatuses />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.paperRoot}>
              <Typography variant="h6" className={classes.heading}>
                Suync GS slots API IDs
              </Typography>
              <Button
                variant="contained"
                onClick={syncGameSuppliers}
                style={{ marginTop: 20 }}
                disabled={syncBtnDisabled}
              >
                Sync GS slots API IDs
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeScreen
