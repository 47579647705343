import React, { useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal"
import Backdrop from "@mui/material/Backdrop"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContentText from "@mui/material/DialogContentText"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import { Constants } from "../../config"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 4),
    width: 650,
    minHeight: 320,
    outline: 0,
  },
  spinner: {
    margin: "0 auto",
    display: "block",
    height: 60,
  },
  title: {
    margin: theme.spacing(2, 0, 1),
    padding: 0,
  },
  subtitle: {
    margin: theme.spacing(0, 0, 4),
  },
  buttonArea: {
    margin: theme.spacing(4, 2, 2),
    textAlign: "right",
  },
  centerText: {
    textAlign: "center",
  },
}))

const ItemAdder = (props) => {
  const classes = useStyles()

  const { open, items, handleClose, handleSave, children } = props

  const [selectedItems, setSelectedItems] = useState()

  const valueChanged = (event, value) => {
    setSelectedItems(value)
  }

  const closeClicked = () => {
    setSelectedItems([])
    handleClose()
  }

  const saveClicked = () => {
    setSelectedItems([])
    handleSave(selectedItems)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={closeClicked}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <DialogTitle className={classes.title}>Select items to add</DialogTitle>
            <DialogContentText className={classes.subtitle}>
              {children}
            </DialogContentText>
            {(!items && (
              <CircularProgress className={classes.spinner} color="inherit" />
            )) ||
              (Array.isArray(items) && items.length < 1 && (
                <DialogContentText className={classes.centerText}>
                  No new items to add
                </DialogContentText>
              )) || (
                <Autocomplete
                  id="item-selector"
                  options={items}
                  getOptionLabel={(option) =>
                    option.name || option[Constants.internalNameColumn]
                  }
                  onChange={(event, value) => {
                    valueChanged(event, value)
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Items"
                      placeholder="Select items"
                    />
                  )}
                />
              )}
            <div className={classes.buttonArea}>
              <Button onClick={closeClicked}>Cancel</Button>
              {Array.isArray(items) && items.length > 0 && (
                <Button
                  disabled={!selectedItems || selectedItems.length < 1}
                  onClick={saveClicked}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

ItemAdder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  items: PropTypes.array,
  children: PropTypes.array,
}

export default ItemAdder
