/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment/moment"
import Typography from "@mui/material/Typography"
import { isOverlapping } from "../../helpers/GeneralHelper"
import { getItems } from "../../helpers/ItemHelper"
import { useAuth0 } from "@auth0/auth0-react"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  inputArea: {
    flexGrow: 0,
    flexBasis: "260px",
    display: "flex",
    flexDirection: "column",
    marginRight: 200,
    "& > div": {
      marginTop: 10,
    },
    "& div:first-child": {
      marginTop: 0,
    },
  },
  listArea: {},
  submitButton: {
    marginTop: 20,
  },
  removeButton: {
    padding: 0,
    color: "#9b9797",
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 250,
    marginTop: 10,
  },
  itemPosition: { fontSize: 24, fontWeight: "bold" },
  itemDates: {},
}))

const ListEditor = ({ value, onChange, apiUrl }) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const classes = useStyles()

  const [positions, setPositions] = useState(value ? JSON.parse(value) : []),
    [input, setInput] = useState({}),
    [itemsMarket, setItemsMarket] = useState(undefined),
    [conflictItemName, setConflictItemName] = useState("")

  const [dateOverlap, setDateOverlap] = useState(false)

  const positionInputChanged = (event) => {
    const value = event.target.value
    setInput({ ...input, position: value ? parseInt(value) : "" })
  }

  const dateInputChanged = (value, attribute) => {
    setInput({ ...input, [attribute]: value.toISOString().split("T")[0] })
  }

  const addListItem = () => {
    let marketHasConflict = false
    let localDateOverlap = false

    itemsMarket
      .filter(
        (item) => item.manual_positions !== null && item.manual_positions !== "[]"
      )
      .forEach((marketItem) => {
        const itemPos = JSON.parse(marketItem.manual_positions)
        const marketConflict = itemPos.some((item) => {
          return isOverlapping(
            item.start_date,
            item.end_date,
            input.start_date,
            input.end_date,
            item.position,
            input.position
          )
        })

        if (marketConflict) {
          marketHasConflict = true
          localDateOverlap = true
          setConflictItemName(marketItem.internal_name)
        }
      })
    if (!marketHasConflict) {
      const conflict = positions.some((item) => {
        return isOverlapping(
          item.start_date,
          item.end_date,
          input.start_date,
          input.end_date
        )
      })

      if (conflict) {
        localDateOverlap = true
      } else {
        setPositions((prevData) => [...prevData, input])
        setInput({})
        setConflictItemName("")
      }
    }

    setDateOverlap(localDateOverlap)
  }
  const removeListItem = (item) => {
    setPositions((prevData) => [...prevData.filter((p) => p !== item)])
  }

  useEffect(() => {
    onChange(JSON.stringify(positions))
  }, [positions, onChange])

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getItems(token, apiUrl, (data, error) => {
        if (data) {
          // console.log("data: ", data)
          setItemsMarket(data)
        } else {
          console.log("Sorry, an error occurred when loading Market data")
        }
      })
    })
  }, [apiUrl])

  return (
    <>
      {itemsMarket ? (
        <div className={classes.root}>
          <div className={classes.inputArea}>
            <TextField
              label="Position"
              type="number"
              step="1"
              size="small"
              value={input?.position || ""}
              onChange={positionInputChanged}
            />
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              dateLibInstance={moment.utc}
            >
              <DatePicker
                label={"Start date"}
                views={["year", "month", "day"]}
                inputProps={{
                  "aria-label": "Start date",
                }}
                onChange={(value) => {
                  dateInputChanged(value, "start_date")
                }}
                value={moment(input.start_date || null)}
              />
              <DatePicker
                label={"End date"}
                views={["year", "month", "day"]}
                inputProps={{
                  "aria-label": "End date",
                }}
                onChange={(value) => {
                  dateInputChanged(value, "end_date")
                }}
                value={moment(input.end_date || null)}
              />
            </LocalizationProvider>
            {dateOverlap && (
              <Typography color="error">
                Cannot add position: The specified date range conflicts with an
                existing entry. `{conflictItemName}`
              </Typography>
            )}
            <Button
              className={classes.submitButton}
              onClick={() => addListItem()}
              disabled={!input.position || !input.start_date || !input.end_date}
            >
              Add position
            </Button>
          </div>
          <div className={classes.listArea}>
            {positions && positions.length > 0 ? (
              <>
                <h3 style={{ marginTop: 0, textAlign: "center" }}>Positions</h3>
                <hr />
                {positions
                  .sort((a, b) => {
                    const keyA = new Date(a.start_date),
                      keyB = new Date(b.start_date)
                    if (keyA < keyB) return -1
                    if (keyA > keyB) return 1
                    return 0
                  })
                  .map((p, i) => (
                    <div key={i} className={classes.item}>
                      <div className={classes.itemPosition}>{p.position}</div>
                      <div className={classes.itemDates}>
                        {p.start_date} <i style={{ margin: "0 5px" }}>to</i>{" "}
                        {p.end_date}
                      </div>
                      <IconButton
                        className={classes.removeButton}
                        aria-label="Remove"
                        onClick={() => removeListItem(p)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
              </>
            ) : (
              <>
                <p>No manual positions have been added</p>
                <p>
                  After adding manual positions, <b>dont forget</b> to click the save
                  button
                </p>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          Sorry, an error occurred when loading Market data
        </div>
      )}
    </>
  )
}

ListEditor.defaultProps = {
  value: "",
  onChange: () => {},
}

ListEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default ListEditor
