export const normalizeValue = (val) => {
  if (val === null || val === undefined) return ""
  // Normalize empty array to ""
  if (Array.isArray(val)) {
    if (val.length === 0) return "" // ⬅️ Handle [] ≈ null
    return val
      .map((item) =>
        typeof item === "object" && item !== null
          ? JSON.stringify(item)
          : String(item)
      )
      .sort()
  }
  if (typeof val === "object") return JSON.stringify(val)
  if (typeof val === "number") return val.toString()

  // ✅ Handle serialized "[]" string as empty
  if (val === "[]") return ""
  return val
}

export const diffFields = (
  current,
  original,
  { returnOnFirstChange = false } = {}
) => {
  if (!current || !original) return returnOnFirstChange ? false : {}

  const result = {}

  for (const key in current) {
    const newVal = normalizeValue(current[key])
    const oldVal = normalizeValue(original[key])

    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      if (returnOnFirstChange) {
        // console.log(`[allowSave] Changed field: "${key}"`, {
        //   newVal: current[key],
        //   oldVal: original[key],
        //   normalizedNew: newVal,
        //   normalizedOld: oldVal,
        // })
        return true
      } else {
        result[key] = current[key] === 0 ? "0" : current[key]
      }
    }
  }

  return returnOnFirstChange ? false : result
}
